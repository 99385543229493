<script src="../../api/proxy.js"></script>
<template>
    <LiefengContentArea :isBack="$route.query.isLineHome" @backClick="$router.push('/residentdatabase')">
        <template v-slot:title>
            社区架构管理
            <!-- <Button type="error" v-if="$route.query.isLineHome" @click="$router.push('/linehomepage')">返回</Button> -->
        </template>
        <template v-slot:toolsbarLeft></template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <div>
                        <Breadcrumb class="bread" separator=">">
                            <BreadcrumbItem v-for="(item, index) in breadCrumb" :key="index">
                                <div style="display: inline-block">
                                    {{ item }}
                                </div>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" :placeholder="areaInput" v-model.trim="selectName" style="width: 200px; margin-right: 5px" @on-search="search"></Input>
                </FormItem>
                <Button type="primary" style="margin-right:10px" @click="search">搜索</Button>
                <Button type="success" style="margin-right:10px" @click="reset">重置</Button>
                <Button type="primary" style="margin-right:10px" @click="merge" v-if="mergePower">合并社区架构</Button>
                <Button type="primary" style="margin-right:10px" @click="$router.push(`/synchronization?communityCode=${communityCode}&isLineHome=${$route.query.isLineHome}`)">
                    导入并同步
                    <span style="color:red">({{ num }})</span>
                </Button>
            </Form>

            <!-- <Dropdown style="margin:0 5px;" @on-click="excelItem" @on-visible-change="toggleItem">
        <Button ref="toggleEx" id="toggleEx" type="error" @click="text" icon="ios-cloud-upload-outline">导入
          <Icon id="iconEx" type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="街巷导入">导入街巷</DropdownItem>
          <DropdownItem name="小区导入">导入小区</DropdownItem>
          <DropdownItem name="楼栋导入">导入楼栋</DropdownItem>
          <DropdownItem name="房号导入">导入房号</DropdownItem>
        </DropdownMenu>
      </Dropdown> -->
            <modalArea ref="modelValue1" :value="showMe1" :fullscreen="false" :toggle-fn1="toggle1" :toggle-fn2="down" :toggle-type1="true" :cahxun="cahxun"></modalArea>

            <Button type="info" icon="ios-download-outline" @click="exportExcel">导出</Button>
            <Button type="info" icon="ios-download-outline" @click="export_community" v-if="export_communityState">导出社区架构房号</Button>
            <Button type="info" icon="ios-download-outline" @click="exportNone">导出空房号</Button>
            <Button type="info" v-if="buttonRoot == 'COMMUNITY_SYS:RESET'" @click="resetCommunity()">重置社区架构</Button>
            <Button type="primary" v-if="toggleGroup !== '1'" @click="chuangFn">批量创建{{ group }}沟通组</Button>
            <Button type="warning" v-if="toggleover" @click="overviewFn">社区建设情况总览</Button>
        </template>
        <template v-slot:contentArea>
            <div class="container">
                <div style="width: 200px" class="left">
                    <Button type="success" icon="md-add" @click="addModal" style="margin-bottom: 10px">添加下级区域</Button>
                    <Tooltip placement="right-start" style="width:100%">
                        <div slot="content" style="width: 200px;white-space: normal">
                            由于社区太多，请输入关键字进行刷选
                        </div>
                        <Select v-model="nodeId" filterable @on-change="getTree" placeholder="点击列表/输入搜索" :remote-method="loadingCommunityFn" :loading="loadingCommunity">
                            <!-- 防止报错，使用了index -->
                            <Option v-for="(item, index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </Tooltip>
                    <Tree ref="mychild" id="btnB" :data="treeData" @on-select-change="handleTree" :load-data="loadData" style="width: 200px" :select-node="true"></Tree>
                </div>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </div>
            <!-- 新增 -->
            <LiefengModal :title="modalTitle" width="500px" :value="addStatus" @input="addStatusFn">
                <template v-slot:contentarea>
                    <AddRegionArea
                        ref="addRegion"
                        @append="append"
                        @hadlePageSize="hadlePageSize"
                        @modify="modify"
                        @modifyTreeNameObj="modifyTreeNameObj"
                        @addStatusFn="addStatusFn"
                        @getTreeAgain="getTreeAgain"
                        :addChangeStatus="addChangeStatus"
                        @resetGetList="resetGetList"
                        :breadCrumb="breadCrumb"
                        :areaObj="areaObj"
                        :treeData="treeData"
                        :communityCode="communityCode"
                        :dataList="dataList"
                        :superiorArea="superiorArea"
                        v-if="addStatus"
                        :infoId="infoId"
                        :codeId="codeId"
                        :level="level"
                        :orgId="orgId"
                        :regionId="regionId"
                        :roadOrgId="roadOrgId"
                        :regionType="regionType"
                        :addFormValitor="addFormValitor"
                        :addFormData="addFormData"
                    ></AddRegionArea>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addStatusFn(false)">取消</Button>
                    <Button type="primary" @click="addModalSave">保存</Button>
                </template>
            </LiefengModal>
            <!-- 导入excel弹窗 -->
            <LiefengModal :title="`导入 ${superiorArea + '：' + toggleNameExexl + '模板的'} 数据`" width="40%" :value="excelStatus" @input="excelModalData">
                <template v-slot:contentarea>
                    <p style="color: #EC8232;margin-bottom: 5px;">注：请选择左侧的区域进行上传街巷、小区、楼栋、房号,未选择默认为上传街巷的!</p>
                    <Upload
                        ref="excelUpload"
                        type="drag"
                        :action="action"
                        name="file"
                        :show-upload-list="false"
                        :before-upload="beforeExcelUpload"
                        :headers="uploadHeaderObj"
                        :on-success="myExcel"
                        :data="uploadData"
                        :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
                        :on-format-error="uploadFormatError"
                    >
                        <div style="padding: 20px 0; text-align: center; width: 100%">
                            <div v-if="dotu" class="dotuClass">
                                <img src="../../../public/images/dotu.gif" alt="" />
                                <span>正在上传中,请稍等...</span>
                            </div>
                            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                            <p>点击上传，或将文件拖拽到此处</p>
                        </div>
                    </Upload>
                    <div v-if="excelFile != null">当前选择的文件名称:{{ excelFile.name }}</div>
                    <!-- <div v-html="errorContent"></div> -->
                    <div style="text-align: center; margin: 20px 0">
                        <Button v-if="!areaObj.regionType && !areaObj.orgLevel" type="default" style="margin-right: 20px">
                            <a href="./excelfiles/importCommunityLevel.xlsx" download=" 街巷导入模板.xlsx">街巷导入模板下载</a>
                        </Button>
                        <Button v-if="areaObj.orgLevel === '6'" type="default" style="margin-right: 20px">
                            <a href="./excelfiles/importRoadLevel.xlsx" download="小区导入模板.xlsx">小区导入模板下载</a>
                        </Button>
                        <Button v-if="areaObj.regionType === '1'" type="default" style="margin-right: 20px">
                            <a href="./excelfiles/importProjectLevel.xlsx" download="楼栋导入模板.xlsx">楼栋导入模板下载</a>
                        </Button>
                        <Button v-if="areaObj.regionType === '2' || areaObj.regionType === '3'" type="default" style="margin-right: 20px">
                            <a href="./excelfiles/importBuildingLevel.xlsx" download="房号导入模板.xlsx">房号导入模板下载</a>
                        </Button>
                        <Button type="info" @click="submitExcel">确认导入</Button>
                    </div>
                </template>
            </LiefengModal>
        </template>
    </LiefengContentArea>
</template>

<script>
//@route('/area')
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengContentArea from "@/components/LiefengContent3"
import modalArea from "@/views/area/modalArea"
import AddRegionArea from "@/views/area/addRegionArea"

import qs from "qs"

export default {
    data() {
        return {
            userButtonRoot: "",
            // 导入数量
            num: 0,
            dotu: false,
            //input提示
            areaInput: "",
            //总览
            toggleover: false,
            nameover: "",
            title: "",
            //沟通组
            group: "",
            toggleGroup: "",
            cahxun: [],
            showMe1: false,
            value: true,
            fullscreen: false,
            cardToggle: false,
            //保存
            baocunId: [],
            baocunNew: [],
            baoListFlag: "",
            //修改弹窗时候显示的所属上级区域
            superiorArea1: "",
            //导入注释
            toggleNameExexl: "1",
            //列表的item
            dataFlag: {},
            //导入模板
            model1: {
                status: "1",
            },

            //end
            dataList: [],
            image: "",
            //拿到表格里面的每一项的orgCode
            tableSelectArr: [],
            ruleValidate: {
                name: [{ required: true, message: "您未输入沟通组名称", trigger: "blur" }],
            },
            modal1: false,
            valSelect: [],
            treeDatatype1: [],
            streets: [], //用于保存新增后的数组，遍历在面包屑上
            superiorArea: "", //添加下级区域中的上级区
            indexList: 0,
            breadCrumb: [], //面包屑
            titleFlag: [],
            areaObj: {}, //保存选择的tree的一些参数
            //异步加载社区
            loadingCommunity: false,
            //tree
            inputVal: "",
            allCommunityList: [],
            nodeList: [],
            orgName: "", //社区名称
            communityProjectCode: "", // 用于动员群推广
            communityCode: "", //社区编码
            nodeId: this.nodeId ? this.nodeId : [],
            treeData: [],
            fullTreeData: [],
            treeVal: "",
            //导入参数
            uploadData: {},
            uploadHeaderObj: { openId: window.sessionStorage.getItem("openId"), Authorization: window.sessionStorage.getItem("accessToken") },
            errorContent: "",
            action: "",
            ruleForm: {
                marital: [{ required: true, message: "请选择社区", trigger: "change" }],
            },
            formValidate: {},
            //excel导入弹窗
            excelStatus: false,
            //excel导入弹窗  社区数据
            communityList: [],
            //excel文件数据
            excelFile: null,
            selectName: "", // 搜索名称或编码
            talbeColumns: [],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,
            codeId: "", //菜单树codeId
            level: "", //树的层级 0:社区；1：街巷：2：小区或单独楼栋；3：小区楼栋
            regionType: "", //区域类型 1：小区；2：小区的楼栋；3：单独的楼栋
            orgId: "", //社区id，新增修改时用
            regionId: "", //小区/楼栋id，新增修改时用
            roadOrgId: "", //街巷id，新增修改时用
            //新增
            addStatus: false,
            modalTitle: "",
            addChangeStatus: false, //新增/修改标识，false代表新增
            infoId: "",
            addFormData: {
                seq: 1,
                type: 5,
            },
            addFormValitor: {
                region: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
                type: [{ required: true, message: "请选择当前属性", trigger: "change" }],
            },
            // zhw添加
            // 获取树节点信息
            selectData: {},

            repeatStatus: false,

            // 导出社区架构房号的状态
            export_communityState: "",

            //合并权限
            mergePower: false,
        }
    },
    watch: {},
    methods: {
        resetCommunity() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>您正在重置${this.selectData.orgName}所有架构数据,检查到该操作为危险操作,请问是否继续？</p>`,
                onOk: () => {
                    this.$Message.loading({
                        content: "正在重置社区架构数据，请稍等...",
                        duration: 0,
                    })

                    this.$post("/gx/pc/organization/emptyOrganization", {
                        orgCode: this.selectData.communityCode,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.destroy()
                            this.reset()
                            this.getTree(this.communityCode)
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },

        // 导出没有房号
        exportNone() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>您正在导出${this.selectData.orgName}没有居民信息的房号数据，是否继续？</p>`,
                onOk: () => {
                    this.$Message.loading({
                        content: "正在导出数据，请稍等...",
                        duration: 0,
                    })
                    this.$post("/syshequ/api/sytop/pc/org/exportRegion", {
                        orgCode: this.selectData.communityCode,
                    }).then(res => {
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 5) == "http") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            let link = document.createElement("a")
                            // link.download = '采集信息数据导出.xlsx';
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        //社区建设情况总览
        overviewFn() {
            this.$router.push({
                path: "/regionaloverview",
                query: {
                    communityCode: this.communityCode,
                    nameover: this.nameover,
                    isLineHome: this.$route.query.isLineHome,
                },
            })
        },

        toggle1() {
            this.showMe1 = true
        },
        down() {
            this.showMe1 = false
        },

        //导入菜单显示状态改变时调用
        toggleItem(visible) {},
        //导入类型选择
        excelItem(name) {
            if (name === "小区导入") {
                if (this.areaObj.orgLevel === "6") {
                    this.importExcel()
                    this.toggleNameExexl = "小区"
                    return
                } else {
                    this.$Message.warning({
                        background: true,
                        content: "请选择左侧的街巷层级进行导入操作！",
                        duration: 2,
                    })
                    return
                }
            } else if (name === "楼栋导入") {
                if (this.areaObj.regionType === "1") {
                    this.importExcel()
                    this.toggleNameExexl = "楼栋"
                    return
                } else {
                    this.$Message.warning({
                        background: true,
                        content: "请选择左侧的小区层级进行导入操作！",
                        duration: 2,
                    })
                    return
                }
            } else if (name === "房号导入") {
                if (this.areaObj.regionType === "2" || this.areaObj.regionType === "3") {
                    this.importExcel()
                    this.toggleNameExexl = "房号"
                    return
                } else {
                    this.$Message.warning({
                        background: true,
                        content: "请选择左侧的楼栋层级进行导入操作！",
                        duration: 2,
                    })
                    return
                }
            } else if (name === "街巷导入") {
                if (!this.areaObj.regionType && !this.areaObj.orgLevel) {
                    this.importExcel()
                    this.toggleNameExexl = "街巷"
                    return
                } else {
                    this.$Message.warning({
                        background: true,
                        content: "默认街巷导入，请不要选择其他区域!",
                        duration: 2,
                    })
                    return
                }
            }
        },
        //修改时刷新列表
        modify() {
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        tableSelect(data) {
            //表格选中
            this.tableSelectArr = JSON.parse(JSON.stringify(data))
        },
        chuangFn() {
            //创建街巷沟通组
            // console.log(this.tableSelectArr, 'this.tableSelectArr')
            if (this.tableSelectArr.length !== 0) {
                this.modal1 = true
            } else {
                //liang
                this.$Message.warning("请勾选需要创建的沟通组")
                return
            }
            let arr = []
            let arr1 = []
            let arr2 = ""
            this.tableSelectArr.map((item, index) => {
                arr.push({
                    orgCode: item.projectCode || item.buildingCode ? null : item.roadCode,
                    regionCode: item.buildingCode ? item.buildingCode : item.projectCode ? item.projectCode : null,
                    regionId: item.roadCode && !item.buildingCode && !item.projectCode ? null : item.regionId,
                })
                arr1.push(item.buildingName ? item.buildingName : item.projectName ? item.projectName : item.roadName ? item.roadName : "")
                arr2 = item.buildingName ? "楼栋" : item.projectName ? "小区" : item.roadName ? "街巷" : ""
            })
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>您正在批量创建 <span style="color:red;margin: 0 3px;">"${arr1.toString()}"【 ${arr2} 】</span> 沟通组，是否继续？</p>`,
                onOk: () => {
                    let params = {
                        creatorId: parent.vue.loginInfo.userinfo.custGlobalId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        orgCode: this.areaObj.communityCode,
                        orgCodeCreate: parent.vue.loginInfo.userinfo.orgCode,
                        regions: arr,
                    }
                    this.$post("/sendmsg/pc/chat/group/createGroupList", params, { "Content-Type": "application/json" }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                background: true,
                                content: "创建成功",
                                duration: 1,
                            })
                            this.tableSelectArr = []
                            this.hadlePageSize({
                                page: this.page,
                                pageSize: this.pageSize,
                            })
                            this.modal1 = false
                        } else {
                            this.$Message.error({
                                background: true,
                                content: res.desc,
                            })
                        }
                    })
                },
            })
        },
        append(data, node) {
            //push进去树的下级
            if (!this.dataList[0].children) {
                this.$set(this.dataList[0], "children", [])
            }
            let children = this.dataList[0].children

            if (!this.areaObj.orgPath && !this.areaObj.regionType && !this.areaObj.orgLevel) {
                //这个是添加社区的下级区域的
                this.treeData.push({
                    title: data.orgName,
                    expand: true,
                    pageSize: 20,
                    page: 1,
                    communityCode: this.communityCode,
                    orgName: this.orgName,
                    orgCode: data.orgCode, //街巷编码
                    projectCode: data.projectCode, //楼栋编码
                    regionType: data.regionType,
                    code: data.code, //区域代码
                    orgId: data.orgId,
                    regionId: data.regionId,
                    communityProjectCode: this.communityProjectCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgLevel: data.orgLevel,
                    orgPath: data.orgPath,
                    seq: data.seq,
                    regionCode: data.regionCode,
                    regionDesc: data.regionDesc,
                    parentId: data.parentId,
                    id: data.orgId,
                })
                this.handleTree(data)
            } else if (this.areaObj.orgLevel === "6") {
                //添加街巷的下级:小区或者单独的楼栋
                if (!data.regionType) {
                    return
                } else {
                    children.push({
                        title: data.regionName ? data.regionName : data.houseNum,
                        expand: true,
                        communityCode: this.communityCode,
                        orgName: this.orgName,
                        orgCode: this.areaObj.orgCode, //街巷编码
                        projectCode: data.projectCode, //楼栋编码
                        regionType: data.regionType,
                        code: data.code, //区域代码
                        orgId: data.orgId,
                        regionId: data.regionId,
                        communityProjectCode: this.communityProjectCode,
                        oemCode: parent.vue.oemInfo.oemCode,
                        orgLevel: data.orgLevel,
                        orgPath: data.orgPath,
                        seq: data.seq,
                        regionCode: data.regionCode,
                        regionDesc: data.regionDesc,
                        parentId: data.orgId,
                        id: data.regionId,
                    })
                    this.handleTree(data)
                }
            } else if (this.areaObj.regionType === "1") {
                //添加小区的下级:小区的楼栋或者房号
                if (!data.regionType) {
                    return
                } else {
                    if (this.areaObj.regionType === "1") {
                        children.push({
                            title: data.regionName ? data.regionName : data.houseNum,
                            expand: true,
                            communityCode: this.communityCode,
                            orgName: this.orgName,
                            orgCode: this.areaObj.orgCode, //街巷编码
                            projectCode: data.projectCode, //楼栋编码
                            regionType: data.regionType,
                            code: data.code, //区域代码
                            orgId: data.orgId,
                            regionId: data.regionId,
                            communityProjectCode: this.communityProjectCode,
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgLevel: data.orgLevel,
                            orgPath: data.orgPath,
                            seq: data.seq,
                            regionCode: data.regionCode,
                            regionDesc: data.regionDesc,
                            parentId: data.parentId,
                            id: data.regionId,
                        })
                        this.handleTree(data)
                    }
                }
            } else if (this.areaObj.regionType === "3") {
                //添加单独的楼栋的:房号
                // children.push({
                //   title: data.regionName ? data.regionName : data.houseNum,
                //   expand: true,
                //   regionType: this.addFormData.type === '6' ? '' : this.addFormData.type,    //liang
                //   communityCode: this.communityCode,
                //   orgName: this.orgName,
                //   orgCode: this.areaObj.orgCode, //街巷编码
                //   projectCode: data.projectCode, //楼栋编码
                //   code: data.code,    //区域代码
                //   orgId: data.orgId,
                //   regionId: data.regionId,
                //   communityProjectCode: this.communityProjectCode,
                //   oemCode: parent.vue.oemInfo.oemCode,
                //   orgLevel: data.orgLevel,
                //   orgPath: data.orgPath,
                //   seq: data.seq,
                //   regionCode: data.regionCode,
                //   regionDesc: data.regionDesc,
                //   parentId: data.houseId,
                //   id: data.regionId,
                // });
                // console.log('添加333')
                // this.handleTree(data)
            }
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        area(status) {
            let arr1 = [
                //点击社区
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "所属社区",
                    key: "communityName",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "街巷/社",
                    key: "roadName",
                    minWidth: 110,
                    align: "center",
                },
            ]
            let arr2 = [
                //街巷下的门牌号
                {
                    title: "门牌号",
                    key: "houseName",
                    minWidth: 130,
                    align: "center",
                },
            ]
            let arr3 = [
                //单独楼栋
                {
                    title: "楼栋",
                    key: "buildingName",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "门牌号/房号",
                    key: "houseName",
                    minWidth: 130,
                    align: "center",
                },
            ]

            let arr4 = [
                //小区
                {
                    title: "小区",
                    key: "projectName",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "楼栋",
                    key: "buildingName",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "房号",
                    key: "houseName",
                    minWidth: 130,
                    align: "center",
                },
            ]

            let arr44 = [
                //小区的楼栋
                {
                    title: "小区",
                    key: "projectName",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "楼栋",
                    key: "buildingName",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "门牌号/房号",
                    key: "houseName",
                    minWidth: 130,
                    align: "center",
                },
            ]

            let arr5 = [
                //是否创建沟通组
                {
                    title: "是否创建沟通组",
                    key: "chatGroup",
                    minWidth: 130,
                    align: "center",
                    render: (h, params) => {
                        let chatGroup = params.row.chatGroup
                        return h("div", [h("span", {}, chatGroup === "0" ? "否" : "是")])
                    },
                },
            ]
            let arr6 = [
                // {
                //   title: "排序",
                //   key: "seq",
                //   width: 100,
                //   align: "center",
                //   sortable: true
                // },
                {
                    title: "操作",
                    width: 250,
                    fixed: "right",
                    align: "center",
                    render: (h, params) => {
                        let data = params.row
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "default",
                                        size: "small",
                                        disabled: params.row.chatGroup === "1",
                                    },
                                    style: {
                                        marginRight: "10px",
                                        display: this.toggleGroup === "1" ? "none" : "inline-block",
                                    },
                                    on: {
                                        click: () => {
                                            this.modal1 = true
                                            let arr = []
                                            let arr1 = []
                                            let arr2 = []
                                            arr1.push(data.buildingName ? data.buildingName : data.projectName ? data.projectName : data.roadName ? data.roadName : "")
                                            arr2 = data.buildingName ? "楼栋" : data.projectName ? "小区" : data.roadName ? "街巷" : ""
                                            arr.push({
                                                orgCode: data.projectCode || data.buildingCode ? null : data.roadCode,
                                                regionCode: data.buildingCode ? data.buildingCode : data.projectCode ? data.projectCode : null,
                                                regionId: data.roadCode && !data.buildingCode && !data.projectCode ? null : data.regionId,
                                            })
                                            this.$Modal.confirm({
                                                title: "温馨提示",
                                                content: `<p>您正在创建 <span style="color:red;margin: 0 3px;">"${arr1.toString()}"【 ${arr2} 】</span> 沟通组，是否继续？</p>`,
                                                onOk: () => {
                                                    let params = {
                                                        creatorId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                        oemCode: parent.vue.oemInfo.oemCode,
                                                        orgCode: this.areaObj.communityCode,
                                                        orgCodeCreate: parent.vue.loginInfo.userinfo.orgCode,
                                                        regions: arr,
                                                    }
                                                    this.$post("/sendmsg/pc/chat/group/createGroupList", params, { "Content-Type": "application/json" }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                background: true,
                                                                content: "创建成功",
                                                                duration: 1,
                                                            })
                                                            this.tableSelectArr = []
                                                            this.hadlePageSize({
                                                                page: this.page,
                                                                pageSize: this.pageSize,
                                                            })
                                                            this.modal1 = false
                                                        } else {
                                                            this.$Message.error({
                                                                background: true,
                                                                content: res.desc,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "创建沟通组"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.modalTitle = "修改区域名称"
                                            this.changeModal(params.row)
                                            this.addFormData.region = this.dataFlag.houseName
                                                ? this.dataFlag.houseName
                                                : this.dataFlag.buildingName
                                                ? this.dataFlag.buildingName
                                                : this.dataFlag.projectName
                                                ? this.dataFlag.projectName
                                                : this.dataFlag.roadName
                                                ? this.dataFlag.roadName
                                                : ""
                                            this.addFormData.seq = parseInt(this.dataFlag.seq || 1)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {},
                                    on: {
                                        click: () => {
                                            this.delRow(data)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ]

            let code = localStorage.getItem("communityCode")
            if (code) {
                //判断第一次进页面有没有communityCode,有则渲染社区
                this.talbeColumns = arr1.concat(arr5, arr6)
                this.areaInput = "按街巷名称搜索"

                if (status === "4") {
                    this.talbeColumns = arr1.concat(arr4, arr5, arr6) //显示街巷和小区
                    this.areaInput = "按小区/楼栋/房号/门牌号搜索"
                } else if (status === "1") {
                    this.talbeColumns = arr1.concat(arr2, arr6) //显示街巷和门牌号
                    this.areaInput = "按门牌号搜索"
                } else if (status === "2") {
                    //小区列表
                    this.talbeColumns = arr1.concat(arr4, arr5, arr6)
                    this.areaInput = "按楼栋/房号/门牌号搜索"
                } else if (this.areaObj.orgLevel === "6" && status === "3") {
                    this.talbeColumns = arr1.concat(arr3, arr5, arr6)
                    this.areaInput = "按楼栋名称搜索"
                }

                if (this.areaObj.regionType === "3") {
                    //单独楼栋

                    this.areaInput = "按房号/门牌号搜索"
                    this.talbeColumns = arr1.concat(arr3, arr6)
                } else if (this.areaObj.regionType === "2") {
                    //表示小区的楼栋

                    this.areaInput = "按房号/门牌号搜索"
                    this.talbeColumns = arr1.concat(arr44, arr6)
                }
            }
        },

        //异步加载社区
        loadingCommunityFn(query) {
            console.log(query, "query")
            if (query !== "" && !Number(query)) {
                this.loadingCommunity = true
                this.nodeList = this.allCommunityList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                if (this.nodeList.length > 50) this.nodeList.length = 50
                this.loadingCommunity = false
            } else if (Number(query)) {
                return
            } else {
                this.nodeList = []
            }
        },

        loadData(item, callback) {
            let params = {
                oemCode: parent.vue.oemInfo.oemCode,
                orgId: Number(item.id) || item.parentId,
                parentId: item.regionId ? item.regionId : 0,
                regionTypeFilter: item.regionType,
            }
            // if (item.regionType === '3' || item.regionType === '2') {
            //   this.$get('/gx/pc/house/selectHouseTreeV2', {
            //     parentId: item.id,
            //   }).then(res => {
            //     if (res.code == '200' && res.dataList !== null) {
            //       let arr2 = res.dataList.map((e) => {  //liang
            //         e.seq = e.seq?e.seq:9999999999
            //         if (e.regionType === "0") {
            //           //4：门牌号(无小区无楼栋)
            //           return {
            //             title: e.name,
            //             regionId: e.id,
            //             oemCode: parent.vue.oemInfo.oemCode,
            //             code: e.code,
            //             parentId: e.parentId,
            //             regionType: e.regionType,
            //             orgCode: item.orgCode,
            //             seq:e.seq?e.seq:0,
            //             ...e
            //           }
            //         }
            //       })
            //       arr2 = arr2.sort(this.sorts_fun('seq'))
            //       console.log(arr2)
            //       item.children = arr2
            //       callback(item.children);
            //     }
            //   })
            // } else {
            this.$get("/gx/pc/region/selectRegionTreeV2", params)
                .then(res => {
                    if (res.code === "200" && res.dataList != null) {
                        let arr = []
                        res.dataList.map(i => {
                            i.seq = i.seq ? i.seq : 9999999999
                            if (i.regionType === "0") {
                                //4：门牌号(无小区无楼栋)
                                arr.push({
                                    title: i.name,
                                    regionId: i.id,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                    code: i.code,
                                    parentId: i.parentId,
                                    regionType: i.regionType,
                                    orgCode: item.orgCode,
                                    ...i,
                                })
                                // return
                            } else if (i.regionType === "4") {
                                //4：门牌号(无小区无楼栋)
                                arr.push({
                                    title: i.name,
                                    regionId: i.id,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                    code: i.code,
                                    parentId: i.parentId,
                                    regionType: i.regionType,
                                    orgCode: item.orgCode,
                                    ...i,
                                })
                                // return
                            }
                            if (i.regionType === "3") {
                                //fuck
                                //1：小区 2：小区的楼栋 3：单独的楼栋"
                                arr.push({
                                    title: i.name,
                                    regionId: i.id,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                    code: i.code,
                                    parentId: i.parentId,
                                    regionType: i.regionType,
                                    orgCode: item.orgCode,
                                    child: item.child,
                                    ...i,
                                })
                            } else if (i.regionType === "2") {
                                arr.push({
                                    title: i.name,
                                    regionId: i.id,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                    code: i.code,
                                    parentId: i.parentId,
                                    regionType: i.regionType,
                                    orgCode: item.orgCode,
                                    child: item.child,
                                    ...i,
                                })
                            } else if (i.regionType === "1") {
                                arr.push({
                                    title: i.name,
                                    regionId: i.id,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                    code: i.code,
                                    parentId: i.parentId,
                                    regionType: i.regionType,
                                    orgCode: item.orgCode,
                                    child: item.child,
                                    ...i,
                                })
                            }
                        })
                        // arr = arr.sort(this.sorts_fun('seq'))
                        console.log(arr)
                        arr = arr.map(item => {
                            // if (item.child === true && item.regionType !== '3' && item.regionType !== '2') {
                            if (item.child === true && item.regionType != "3" && item.regionType != "2") {
                                return {
                                    loading: false,
                                    children: [],
                                    ...item,
                                }
                            } else {
                                return {
                                    ...item,
                                }
                            }
                        })
                        console.log(arr)
                        console.log(item)
                        if (arr.length == 0) {
                            console.log()
                            item.loading = false
                            delete item.children
                            delete item.loading
                            console.log(item)
                        } else {
                            item.children = arr
                            callback(item.children)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            // }
        },

        selectNode(id) {
            // 第一次created创建后触发改函数，传了this.userSessionId
            if (id) {
                let custGlobalId = id
                this.$get("/gx/pc/staff/selectDatsScopeAndFilterLevel", {
                    custGlobalId: custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    filterLevel: 5,
                    oemCode: parent.vue.oemInfo.oemCode,
                })
                    .then(res => {
                        if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                            // this.nodeId 保存社区编码的数组
                            // this.nodeId = res.dataList[0].orgCode != '' ? res.dataList[0].orgCode.split(",") : [];   //修改判断条件，否则出错直接执行catch

                            let communityCode = this.$core.getUrlParam("communityCode")

                            if (communityCode) {
                                let findRes = res.dataList.find(v => {
                                    return v.orgCode === communityCode
                                })

                                this.communityCode = findRes.orgCode //code 社区编码
                                this.orgName = findRes.orgName //社区名字
                                this.orgId = findRes.orgId //社区Id
                            } else {
                                this.communityCode = res.dataList[0].orgCode //code 社区编码
                                this.orgName = res.dataList[0].orgName //社区名字
                                this.orgId = res.dataList[0].orgId //社区Id
                            }

                            this.getTree(this.communityCode) //第一次进来默认获取第一个社区数据
                            this.dataList = [{}]
                            this.nodeList = res.dataList.map(item => {
                                return {
                                    value: item.orgCode, //社区编码
                                    label: item.orgName, //社区名字
                                    projectCode: item.projectCode, //小区编码
                                    orgId: item.orgId,
                                    orgLevel: item.orgLevel,
                                    orgPath: item.orgPath,
                                    orgType: item.orgType,
                                    parentId: item.parentId,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                }
                            })
                            this.allCommunityList = JSON.parse(JSON.stringify(this.nodeList))
                            if (this.nodeList.length > 50) {
                                this.nodeList.length = 50
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.$Message.error("无法获取用户id")
            }
        },
        getTree(val) {
            localStorage.setItem("communityCode", val)
            this.nodeList.map((item, index) => {
                //遍历数组，重新获取新的选中社区编码和名字
                if (item.value == val) {
                    this.orgId = item.orgId
                    this.orgName = item.label //社区名字
                    this.communityCode = item.value //这个是社区编号
                    this.communityProjectCode = item.projectCode //这个projectCode是小区编码
                }
            })
            this.treeData = []
            this.handleTree() //第一次加载进来默认展示表格数据

            if (val && val.length > 0) {
                this.$get("/gx/pc/organization/selectOrganizationByOrgCodeV2", {
                    orgCode: this.communityCode,
                }).then(re => {
                    if (re && re.code === "200" && re.dataList != null) {
                        let temp = re.dataList.map(item => {
                            item.seq = item.seq ? item.seq : 9999999999
                            return {
                                title: item.name, //街巷名字
                                orgCode: item.code, //街巷编号
                                oemCode: parent.vue.oemInfo.oemCode, //
                                parentId: item.parentId, //父id
                                id: item.id,
                                orgLevel: item.orgLevel,
                                seq: item.seq,
                                child: item.child,
                                ...item,
                            }
                        })
                        // temp = temp.sort(this.sorts_fun('seq'))
                        console.log(temp)
                        temp = temp.map(item => {
                            if (item.child === true) {
                                return {
                                    loading: false,
                                    children: [],
                                    ...item,
                                }
                            } else {
                                return {
                                    ...item,
                                }
                            }
                        })

                        this.treeData = temp //渲染树的结构
                    }
                })
            }
        },

        // 选择tree
        handleTree(data) {
            console.log(data, "看看")
            if (data === []) {
                data = undefined
            } else if (data && data.length > 0) this.dataList = data

            // console.log(data,'obj选择tree')
            if (data && data[0]) {
                let temp = {
                    pageSize: 20,
                    page: 1,
                    inviterFlag: this.inviterFlag,
                    communityCode: this.communityCode,
                    orgName: this.orgName,
                    orgCode: data[0].orgCode, //街巷编码
                    projectCode: data[0].projectCode, //楼栋编码
                    regionType: data[0].regionType,
                    code: data[0].code, //区域代码
                    orgId: data[0].orgId,
                    regionId: data[0].regionId,
                    communityProjectCode: this.communityProjectCode,
                    title: data[0].title,
                    expand: true,
                    dimensionId: data[0].dimensionId,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgLevel: data[0].orgLevel,
                    orgPath: data[0].orgPath,
                    parentId: data[0].parentId,
                    seq: data[0].seq,
                    regionCode: data[0].regionCode,
                    regionDesc: data[0].regionDesc,
                    id: data[0].id,
                }
                this.getList(temp)
            } else {
                let temp = {
                    inviterFlag: this.inviterFlag,
                    orgName: this.orgName,
                    orgId: this.orgId,
                    communityCode: this.communityCode,
                    pageSize: 20,
                    page: 1,
                    communityProjectCode: this.communityProjectCode,
                }
                this.getList(temp)
            }
        },

        async getList(obj) {
            this.areaObj = obj
            console.log(obj, "obj")
            this.tableSelectArr = []
            this.nameover = obj.orgName
            let title = obj.title //用于面包屑的最后一个
            this.superiorArea = obj.title || obj.orgName
            this.breadCrumb = []
            this.baocunId = []
            this.getCurrentTitle(obj.parentId, this.treeData) //面包屑
            this.breadCrumb.unshift(obj.orgName) //liang
            if (title !== undefined) {
                this.breadCrumb.push(title)
                this.baocunId.push(obj.id)
            }

            this.selectData = obj
            this.codeId = ""
            this.level = "0"
            this.regionType = ""
            if (obj.projectCode) {
                //如果存在，则表示是小区或者楼栋
                if (obj.regionType == "1") {
                    //fuck
                    //regionType== 2，表示小区
                    await this.area() //区域
                } else if (obj.regionType == "2") {
                    //fuck
                    //regionType== 2,表示小区楼栋
                    await this.area() //区域
                } else if (obj.regionType == "3") {
                    //regionType== 3,表示单独楼栋
                    await this.area() //区域
                } else if (obj.regionType === "4") {
                    //regionType===4 , 表示门牌号
                    await this.area() //区域
                }
                this.codeId = obj.projectCode
                this.regionId = obj.projectCode
            } else if (obj.roadCode) {
                //如果存在，则表示街道
                this.level = "1"
                this.codeId = obj.roadCode
                this.roadOrgId = obj.roadCode
                await this.area() //区域
            } else {
                //否则，就是社区
                this.level = "0"
                this.codeId = obj.communityCode
                await this.area() //区域
            }
            if (obj.regionType) {
                this.regionType = obj.regionType
            }
            await this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        //递归面包屑
        getCurrentTitle(obj, treeData) {
            for (let i = 0; i < treeData.length; i++) {
                if (treeData[i].id == obj) {
                    this.breadCrumb.unshift(treeData[i].title)
                    this.baocunId.unshift(treeData[i].id)
                    // console.log(treeData[i].orgLevel)
                    if (!treeData[i].orgLevel) {
                        this.getCurrentTitle(treeData[i].parentId, this.treeData)
                    }
                    return
                } else if (treeData[i].children && treeData[i].children.length > 0) {
                    this.getCurrentTitle(obj, treeData[i].children)
                }
            }
        },

        getNum() {
            this.$get("/gx/pc/orgDraft/selectStatusCount", {
                orgCode: this.communityCode,
                syncStatus: 1,
            }).then(res => {
                if (res.code == 200) {
                    this.num = res.data
                }
            })
        },

        hadlePageSize(obj) {
            this.getNum()
            this.loading = true
            this.$get("/syshequ/api/sytop/pc/org/selectRegionTreePage", {
                communityCode: this.communityCode, //社区编码
                oemCode: parent.vue.oemInfo.oemCode,
                name: this.selectName,
                roadCode: this.areaObj.orgCode || "", //街巷编码
                parentId: this.areaObj.regionId ? this.areaObj.regionId : !this.areaObj.regionType ? 0 : this.areaObj.id,
                page: obj.page,
                pageSize: obj.pageSize,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.toggleover = true

                        let arr = ""
                        let arr1 = ""
                        let arr2 = ""
                        res.dataList.map((item, index) => {
                            //如果树节点没有小区和楼栋则显示门牌号的列表

                            if (item.projectName) {
                                //小区
                                arr = "1"
                            }
                            if (item.buildingName) {
                                //楼栋
                                arr1 = "3"
                            }
                            if (item.houseName) {
                                //房号/门牌号
                                arr2 = "4"
                            }

                            if (this.areaObj.orgLevel === "6" && arr === "1") {
                                //街巷下显示小区
                                this.area("4")
                                this.toggleGroup = ""
                                return
                            } else if (this.areaObj.orgLevel === "6" && arr2 === "4" && arr !== "1" && arr1 !== "3") {
                                //街巷下显示门牌号
                                this.area("1")
                                this.toggleGroup = "1"
                                return
                            } else if (this.areaObj.regionType === "1" && arr === "1") {
                                //有小区则显示小区列表
                                this.area("2")
                                this.toggleGroup = ""
                                return
                            } else if (this.areaObj.orgLevel === "6" && arr1 === "3") {
                                //有楼栋则显示楼栋列表
                                this.area("3")
                                this.toggleGroup = ""
                                return
                            }

                            if (arr1 === "3" && arr2 === "4") {
                                this.toggleGroup = "1"
                            }

                            if (item.chatGroup === "1") {
                                //已经创建沟通组了的就禁用选择框
                                item._disabled = true
                            }
                        })

                        this.tableData = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败",
                    })
                })
        },

        //新增
        addModal() {
            if (this.areaObj.regionType === "0" || this.areaObj.regionType === "4") {
                this.$Message.warning({
                    background: true,
                    content: "该区域为最后区域，无法添加下级!",
                    duration: 1,
                })
                return
            }
            this.modalTitle = "添加下级区域"
            this.addFormData = {
                seq: 1,
                type: 5,
            }

            this.addStatus = true
            this.addChangeStatus = false
        },
        //新增/修改后重新获取菜单树列表
        resetGetList() {
            this.getTree(this.communityCode)
        },
        //修改excel导入弹窗状态
        excelModalData(status) {
            this.excelStatus = status
        },
        //导入按钮
        importExcel() {
            ;(this.image =
                this.areaObj.communityCode && !this.areaObj.orgLevel && !this.areaObj.regionType
                    ? "importCommunityLevel"
                    : this.areaObj.orgLevel === "6"
                    ? "importRoadLevel"
                    : this.areaObj.regionType === "1"
                    ? "importProjectLevel"
                    : this.areaObj.regionType === "3" || this.areaObj.regionType === "2"
                    ? "importBuildingLevel"
                    : ""),
                (this.action = window.vue.getProxy()["/gx"].target + "/upload/region/" + `${this.image}`),
                (this.uploadData = {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgId: this.areaObj.id ? this.areaObj.id : this.orgId,
                    regionCode: this.areaObj.code,
                })
            this.excelStatus = true
            this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
                staffId: parent.vue.loginInfo.userinfo.id,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList && res.dataList.length > 0) {
                        this.communityList = res.dataList
                    } else {
                        this.$Message["error"]({
                            background: true,
                            content: "获取社区数据失败！",
                        })
                    }
                })
                .catch(err => {
                    this.$Message["error"]({
                        background: true,
                        content: "无法导入数据！",
                    })
                })
        },
        //选择社区
        selectCommunity(code) {
            this.uploadData.orgCode = code
            this.communityList.map((item, index) => {
                if (item.code == code) {
                    this.uploadData.orgName = item.fullName
                }
            })
        },

        //确认导入
        submitExcel() {
            if (this.excelFile === null) {
                this.$Notice.warning({
                    title: "请选择上传文件",
                    desc: this.errorContent,
                    duration: 3,
                })
            } else {
                this.loading = true
                this.$Loading.start()
                this.dotu = true
                this.$refs.excelUpload.post(this.excelFile)
            }
        },
        //导入excel格式错误
        uploadFormatError(file) {
            this.$Notice.warning({
                title: "文件格式不正确",
                desc: "文件" + file.name + "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
            })
        },
        //excel文件上传成功后执行
        myExcel(res) {
            // console.log(res,'成功的钩子')
            if (res.code == 200) {
                this.$Message.success({
                    background: true,
                    content: "上传成功！",
                })
                this.loading = false
                this.reloadTree()
                this.$Loading.finish()
                this.dotu = false
                this.cahxun = []
                if (res.dataList && res.dataList.length > 0) {
                    res.dataList.map(item => {
                        this.cahxun.push(item)
                    })
                    // this.toggle1()
                }
                this.excelStatus = false
                this.excelFile = null
                this.repeatStatus = true
                this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize,
                })
                //
                //this.getTree(this.communityCode);
                //
            } else {
                this.errorContent = ""
                // res.dataList.map((item, index) => {
                //   this.errorContent += `<div style="color:red">第${item.number +
                //   2}行：${item.errorCode}</div>`;
                // });
                this.$Notice.error({
                    title: "导入失败",
                    desc: this.errorContent,
                    duration: 3,
                })
                this.excelFile = null
                this.$Loading.error()
            }
        },
        //上传之前
        beforeExcelUpload(file) {
            ;(this.action = window.vue.getProxy()["/gx"].target + "/upload/region/" + `${this.image}`),
                (this.uploadData = {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgId: this.areaObj.id ? this.areaObj.id : this.orgId,
                    regionCode: this.areaObj.code,
                })
            this.excelFile = file
            return false
        },
        //导入模板刷新展开
        excelTreeObj1(code, tree) {
            for (let i in tree) {
                if (tree[i].code == code || tree[i].id == code) {
                    tree.splice(i, 1, {
                        ...tree[i],
                        expand: true,
                        selected: true,
                    })
                    this.excelTreeObj1(tree[i].parentId, this.treeData)
                } else if (tree[i].children && tree[i].children.length > 0) {
                    this.excelTreeObj1(code, tree[i].children)
                }
            }
        },
        //删除tree对象
        deleteTreeObj(code, tree) {
            for (let i in tree) {
                if (tree[i].code == code && tree[i].orgCode) {
                    tree.splice(i, 1)
                    return
                } else if (tree[i].orgCode == code && !tree[i].code) {
                    tree.splice(i, 1)
                    return
                } else if (tree[i].children && tree[i].children.length > 0) {
                    this.deleteTreeObj(code, tree[i].children)
                }
            }
        },
        //删除
        delRow(data) {
            console.log("删除前看看数据", data)
            this.$Modal.confirm({
                title: "温馨提示",
                content:
                    "<p>您正在删除" +
                    "<span style='color:red;margin: 0 3px;'>" +
                    `${data.houseName ? data.houseName : data.buildingName ? data.buildingName : data.projectName ? data.projectName : data.roadName ? data.roadName : ""}` +
                    "</span>" +
                    "该数据，此操作不可逆，是否继续？</p>",
                onOk: () => {
                    if (data.houseCode && data.houseCode != "") {
                        this.$post("/syshequ/api/sytop/pc/org/deleteHouse", {
                            houseCode: data.houseCode,
                        }).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "删除成功",
                                    duration: 1,
                                })
                                this.resetGetList()
                                this.deleteTreeObj(data.houseCode || data.buildingCode || data.projectCode || data.roadCode, this.treeData)
                                this.hadlePageSize({
                                    page: this.page,
                                    pageSize: this.pageSize,
                                })
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                    } else {
                        this.$post(
                            //  "/gx/pc/region/deleteRegionV2"
                            "/syshequ/api/sytop/pc/org/deleteRegionV2",
                            {
                                roadCode: data.buildingCode === null && data.projectCode === null && data.houseCode === null ? data.roadCode : null,
                                regionCode:
                                    data.houseCode && !data.buildingCode && !data.projectCode
                                        ? data.houseCode
                                        : data.buildingCode && !data.houseCode
                                        ? data.buildingCode
                                        : data.projectCode && !data.buildingCode
                                        ? data.projectCode
                                        : data.projectCode && data.buildingCode && data.houseCode
                                        ? null
                                        : null,
                                // houseCode: data.houseCode && data.buildingCode || data.projectCode ? data.houseCode : null
                            }
                        ).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "删除成功",
                                    duration: 1,
                                })
                                this.resetGetList()
                                this.deleteTreeObj(data.houseCode || data.buildingCode || data.projectCode || data.roadCode, this.treeData)
                                this.hadlePageSize({
                                    page: this.page,
                                    pageSize: this.pageSize,
                                })
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                    }
                },
            })
        },
        //新增
        addStatusFn(status) {
            this.addStatus = status
        },

        //修改树节点的名称
        modifyTreeNameObj(code, tree) {
            for (let i in tree) {
                if (tree[i].code == code && tree[i].orgCode) {
                    tree.splice(i, 1, {
                        ...tree[i],
                        title: this.addFormData.region,
                    })
                    return
                } else if (tree[i].orgCode == code && !tree[i].code) {
                    tree.splice(i, 1, {
                        ...tree[i],
                        title: this.addFormData.region,
                    })
                    return
                } else if (tree[i].children && tree[i].children.length > 0) {
                    this.modifyTreeNameObj(code, tree[i].children)
                }
            }
        },
        //保存按钮
        addModalSave() {
            this.$refs.addRegion.save(this.dataFlag)
        },
        //修改
        changeModal(data) {
            this.dataFlag = data //表格的每一项
            this.addChangeStatus = true
            this.addStatus = true
            // this.$Message.loading({
            //   duration: 0,
            //   content: "正在加载数据..."
            // });
            // this.$post("/gx/pc/region/queryRegionDetail", {
            //   id,
            //   level: this.level,
            //   regionType: this.regionType
            // })
            //     .then(res => {
            //       if (res.code == 200) {
            //         this.addFormData = res.data;
            //         this.addStatus = true;
            //         this.$Message.destroy();
            //       } else {
            //         this.$Message.destroy();
            //         this.$Message.error({
            //           background: true,
            //           content: res.desc
            //         });
            //       }
            //     })
            //     .catch(err => {});
        },
        //搜索
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        reset() {
            this.selectName = ""
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },

        // zhw添加代码
        // 导出功能
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>您正在导出${this.selectData.orgName}的数据，是否继续？</p>`,
                onOk: () => {
                    this.$Message.loading({
                        content: "正在导出数据，请稍等...",
                        duration: 0,
                    })
                    this.axios({
                        url: window.vue.getProxy()["/gx"].target + "/upload/region/exportRegion",
                        data: qs.stringify({
                            orgCode: this.selectData.communityCode,
                        }),
                        method: "POST",
                        responseType: "blob",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            Authorization: window.sessionStorage.getItem("accessToken"),
                        },
                    }).then(res => {
                        this.download(res.data, this.selectData.orgName)
                    })
                },
            })
        },
        download(data, name) {
            const url = window.URL.createObjectURL(
                new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            )
            const link = document.createElement("a")
            link.style.display = "none"
            link.href = url
            link.setAttribute("download", `${name}社区-地址初始化数据`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
            this.$Message.destroy()
        },

        text() {},

        reloadTree() {
            //
            if (this.baocunId && this.baocunId.length > 0) {
                var id = this.baocunId[this.baocunId.length - 1]
                this.reloadTreeLevel(id, this.treeData)
            } else {
                this.getTree(this.communityCode)
            }
        },

        //遍历树的，导入成功把点击的那个节点为null，然后在重新加载它
        reloadTreeLevel(obj, treeData) {
            for (let i = 0; i < treeData.length; i++) {
                if (treeData[i].id == obj) {
                    treeData[i].children = null
                    this.loadData(treeData[i], children => {
                        //  console.log(children)
                    })
                    break
                } else if (treeData[i].children && treeData[i].children.length > 0) {
                    this.reloadTreeLevel(obj, treeData[i].children)
                }
            }
        },

        // 导出社区架构房号表
        export_community() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>您正在导出${this.selectData.orgName}的社区架构数据，是否继续？</p>`,
                onOk: () => {
                    this.$Message.loading({
                        content: "正在导出数据，请稍等...",
                        duration: 0,
                    })
                    this.$get("/gx/pc/region/downRegionScopeHouseNum").then(res => {
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 5) == "http") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            let link = document.createElement("a")
                            // link.download = '采集信息数据导出.xlsx';
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        // 合并社区架构
        merge() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否合并当前社区架构",
                loading: true,
                onOk: () => {
                    this.$post("/orgzz/pc/organization/mergeRegionScope", {
                        orgCode: this.communityCode,
                    }).then(res => {
                        this.$Modal.remove()
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "合并成功",
                                background: true,
                            })
                            this.getTree(this.communityCode)
                        } else {
                            this.$Message.error({
                                content: "合并失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        // 按键值对排序
        sorts_fun(e) {
            return function(a, b) {
                var v1 = a[e]
                var v2 = b[e]
                return v1 - v2
            }
        },
    },
    created() {
        this.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId
        this.oemCode = parent.vue.oemInfo.oemCode
        this.userSessionId = parent.vue.loginInfo.userinfo.id
        let userButtonRoot = parent.vue.userButtonRoot
        console.log(userButtonRoot)
        this.export_communityState = userButtonRoot.includes("1003" ? "1003" : "")
        this.mergePower = userButtonRoot.includes("MEREG_ORG") ? true : false
        this.buttonRoot = userButtonRoot.includes("COMMUNITY_SYS:RESET") ? "COMMUNITY_SYS:RESET" : ""
    },
    beforeMount() {
        this.tableHeight = window.innerHeight - 200 + ""
    },
    mounted() {
        this.selectNode(this.custGlobalId)
    },
    components: {
        LiefengTable,
        LiefengModal,
        LiefengContentArea,
        AddRegionArea,
        modalArea,
    },
}
</script>

<style scoped lang="less">
.dotuClass {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bread {
    background-color: rgba(0, 0, 0, 0);
}

/deep/ .ivu-tree-title-selected {
    background: #00ffff;
}

/deep/ .ivu-tree-title:hover {
    background: #f08080;
}

/deep/ .ivu-breadcrumb {
    padding: 0;
    margin: 0;
}

/deep/ .ivu-modal-body {
    padding-bottom: 1px;
}

.formStyle {
    text-align: right;
}

.toggle1 {
    color: red;
    display: inline-block;
}

.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 85px);
    width: calc(100vw - 20px);
    > div:nth-last-child(1) {
        width: calc(100vw - 245px);
    }

    /deep/ .left {
        margin: 0 10px;
        width: 200px;
        height: 100%;

        /deep/ .ivu-select-dropdown {
            height: calc(100vh - 160px);
            max-height: calc(100vh - 160px);
        }
    }

    /deep/ .ivu-tree {
        padding: 0 6px;
        border-left: 1px solid #dcdee2;
        border-right: 1px solid #dcdee2;
        border-bottom: 1px solid #dcdee2;
        height: calc(100vh - 177px);
        overflow: auto;
    }
}
</style>
