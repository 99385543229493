<template>
    <Form ref="addForm" :model="addFormData" :rules="addFormValitor" :label-width="90" :label-colon="true">
      <FormItem label="所属上级" >
        <div v-if="!addChangeStatus">{{ superiorArea }}</div>
        <div v-else-if="addChangeStatus">
          <Breadcrumb class="bread" separator=">">
          <BreadcrumbItem v-for="(item,index) in breadCrumb" :key="index">
            <div style="display: inline-block">
              {{ item }}
            </div>
          </BreadcrumbItem>
        </Breadcrumb>
        </div>
      </FormItem>
      <FormItem label="当前属性" prop="type" v-if="!this.addChangeStatus">
        <RadioGroup v-model="addFormData.type">
          <Radio label="1" v-if="areaObj.orgLevel==='6'">小区</Radio>
          <Radio label="2" v-if="areaObj.regionType==='1'">楼栋</Radio>
          <Radio label="3" v-if="areaObj.orgLevel==='6'">楼栋</Radio>
          <Radio label="4" v-if="areaObj.orgLevel==='6'">房号</Radio>
          <Radio label="0" v-if="areaObj.regionType==='3'||areaObj.regionType==='2'||areaObj.regionType==='1'">房号
          </Radio>
          <Radio label="6" v-else-if="!areaObj.title &&!areaObj.regionType &&areaObj.communityCode">街巷/社</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="区域名称" prop="region">
        <Input :maxlength="20"  v-model.trim="addFormData.region"></Input>
      </FormItem>
      <FormItem label="排序">
        <InputNumber :min="1" :max='999999999' v-model="addFormData.seq"></InputNumber>
      </FormItem>
    </Form>
</template>

<script>
   export default {
     props: ['codeId', 'infoId', 'addChangeStatus', 'addFormData', 'addFormValitor', 'level', 'orgId', 'regionId', 'regionType', 'roadOrgId', 'areaObj', 'superiorArea', 'communityCode', 'dataList','treeData','breadCrumb'],
     data() {
       return {
         breadcrumbList: [],
         treeDatatype:[]
       }
     },
     methods: {

       save(data) {
         this.$refs.addForm.validate(status => {
           if (status) {
             if (this.addChangeStatus) {
               console.log('data',data);
               this.$post('/gx/pc/region/regionUpdate', {
                 orgCode: data.houseCode||
                 data.buildingCode||data.projectCode?'':data.roadCode,
                 regionCode:data.houseCode? data.houseCode :
                     data.buildingCode? data.buildingCode:
                         data.projectCode? data.projectCode :
                             '',
                 regionDesc: '',   //区域描述
                 regionName:  this.addFormData.region, //区域名称,
            //      regionType: (data.projectCode&&data.buildingCode&&data.houseCode)?'0':(data.projectCode && data.communityCode && !data.buildingCode)?'1':(data.buildingCode&&data.projectCode) ? '2':
            //  (data.buildingCode&&!data.projectCode) ? '3' : (data.projectCode) ?'1': (!data.projectCode&&data.houseCode)?'4':null,   //区域类型
                 regionType:data.regionType,
                 seq: this.addFormData.seq,   //排序
               }).then(res => {
                 if (res.code == 200) {
                   this.$Message.success({
                     background: true,
                     content: "修改成功",
                     duration: 1
                   });
                   this.$emit('addStatusFn', false);
                   this.$emit('modify') //触发事件刷新列表
                   this.$emit('resetGetList') 
                   this.$emit('modifyTreeNameObj',data.houseCode||data.buildingCode||data.projectCode||data.roadCode,this.treeData)
                 } else {
                   this.$Message.error({
                     background: true,
                     content: res.desc,
                     duration: 1
                   })
                 }
               })
             } else {
                try{
                  this.$post('/gx/pc/region/regionAdd', {
                    communityCode: this.communityCode,   //社区编码
                    orgCode: this.areaObj.orgCode || '',    //街巷编码
                    parentId: this.dataList[0].regionId ? this.dataList[0].regionId : 0,  //
                    regionName: this.addFormData.region, //区域名称
                    regionType: this.addFormData.type === '6' ? '' : this.addFormData.type,   //区域类型
                    seq: this.addFormData.seq,   //排序
                    regionDesc: 0,   //区域描述
                  }).then(res => {
                    if (res.code == 200) {
                      this.treeDatatype = res.data

                      this.$Message.success({
                        background: true,
                        content: "新增成功",
                        duration: 1
                      });
                      // this.$emit('resetGetList') 
                      this.$emit('addStatusFn', false);
                      this.$emit('append', this.treeDatatype)
                    } else {
                      this.$Message.error({
                        background: true,
                        content: res.desc,
                        duration: 1
                      })
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                }catch(err){
                }


             }
           }
         })
       }
     },
     created() {
       // this.$post('/gx/pc/region/getRegionParentNames', {
       //   code: this.codeId,
       //   level: this.level,
       //   regionType: this.regionType
       // }).then(res => {
       //   if (res.code == 200) {
       //     console.log(res,'ljj 200查看区域')
       //     this.breadcrumbList = [];
       //     if (res.data.communityName) this.breadcrumbList.push(res.data.communityName);
       //     if (res.data.roadName) this.breadcrumbList.push(res.data.roadName);
       //     if (res.data.regionName) this.breadcrumbList.push(res.data.regionName);
       //     if (res.data.buildName) this.breadcrumbList.push(res.data.buildName);
       //
       //   } else {
       //     this.$Message.error({
       //       background: true,
       //       content: "获取当前区域所属的上级失败"
       //     })
       //   }
       // })
     }
   }
</script>

<style scoped lang='less'>

</style>
